.orange {
    position: absolute;
    top:0;
    left:0;
    background-color:rgb(202, 113, 41);
    width:100%;
    height:4.5rem;
    z-index: -1;
    box-shadow: 1px 1px 3px 1px #ccc;
    -moz-box-shadow: 1px 1px 3px 1px #ccc;
}

@media (max-width: 450px) {
    .orange {
        height:5.5rem;
    }
}

.spacer {
    flex:1;
}

.banner {
    color:rgb(241, 241, 241);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
}

@media (max-width: 450px) {
    .banner {
        flex-direction: column;
    }
}

.banner .site-links {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
}

.banner h1 {
    margin: 0px auto;
    font-size: 1.5em;
    padding-left: 1em;
    padding-right: 1em;
    font-weight: 100;
    white-space: nowrap;
}

.banner h2 {
    font-size: 1.25em;
    padding-left: 1em;
    padding-right: 1em;
    font-weight: 100;
}

.banner a {
    color:rgb(241, 241, 241);
}

.banner a:link {
    text-decoration: none;
}
  
.banner a:visited {
    text-decoration: none;
}
  
.banner a:hover {
    text-decoration: underline;
}
  
.banner a:active {
    text-decoration: underline;
}

.copyright {
    width: 100%;
    text-align: center;
    font-size:12px;
    padding:4em;
    padding-top:0em;
}

.copyright a {
    text-decoration: none;
    cursor: default;
    color:rgb(175, 175, 175);
} pre {border: solid black 1px;}